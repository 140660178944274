import React from 'react'

import { DefaultLayout } from '~/layouts'

import Content from './content'

interface PublicLoginProps {
  onSubmit: (values: any) => Promise<{ [x: string]: unknown }>
  validate: (values: Record<string, unknown>) => { [x: string]: unknown }
}

const PublicLogin = (props: PublicLoginProps) => {
  return (
    <DefaultLayout>
      <Content {...props} />
    </DefaultLayout>
  )
}

export default PublicLogin
