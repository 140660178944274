import * as React from 'react'
import { Form as FinalForm } from 'react-final-form'
import dynamic from 'next/dynamic'
import Link from 'next/link'
import { up } from 'styled-breakpoints'
import styled from 'styled-components'
import { useFlag } from 'toggled'

import { Loader } from '~/components'
import Button from '~/truck/button'
import Label from '~/truck/label'
import { flags } from '~/utils/constants'

import { Block, Container, Divider, Heading } from '../elements'

const LoginForm = dynamic(() => import('~/components/login-form'), {
  loading: () => <Loader />,
  ssr: false,
})

const HideSignUpLoginForm = styled(LoginForm)`
  ${up('md')} {
    min-width: ${props => props.theme.spacing.cozy * 45}px;
  }
`
const Content = props => {
  const { onSubmit, validate } = props

  const hasHideSignUpFF = useFlag(flags.HIDE_SIGN_UP)

  if (hasHideSignUpFF) {
    return (
      <Container hideSignUp>
        <Heading>Bienvenido</Heading>
        <FinalForm onSubmit={onSubmit} validate={validate}>
          {formProps => {
            const { handleSubmit, submitting, submitErrors } = formProps

            return (
              <HideSignUpLoginForm handleSubmit={handleSubmit} submitting={submitting} submitErrors={submitErrors} />
            )
          }}
        </FinalForm>
      </Container>
    )
  }

  return (
    <Container reverseWrap>
      <Heading>Bienvenido</Heading>
      <Block>
        <Label $textStyle="h5Semibold">¿Aún no tienes una cuenta?</Label>
        <Link href="/registrarse">
          <Button.Ghost>Regístrate</Button.Ghost>
        </Link>
      </Block>
      <Divider />
      <Block>
        <Label $textStyle="h5Semibold">Inicia sesión con tu cuenta</Label>
        <FinalForm onSubmit={onSubmit} validate={validate}>
          {formProps => {
            const { handleSubmit, submitting, submitErrors } = formProps

            return <LoginForm handleSubmit={handleSubmit} submitting={submitting} submitErrors={submitErrors} />
          }}
        </FinalForm>
      </Block>
    </Container>
  )
}

export default Content
