import React from 'react'
import { Form as FinalForm } from 'react-final-form'
import dynamic from 'next/dynamic'

import { Loader } from '~/components'
import { PrivateAuthLayout } from '~/layouts'

import { Heading } from '../elements'

interface PrivateLoginProps {
  onSubmit: (values: any) => Promise<{ [x: string]: unknown }>
  validate: (values: Record<string, unknown>) => { [x: string]: unknown }
}

const LoginForm = dynamic(() => import('~/components/login-form'), {
  loading: () => <Loader />,
  ssr: false,
})

const PrivateLogin = (props: PrivateLoginProps) => {
  const { onSubmit, validate } = props

  return (
    <PrivateAuthLayout>
      <Heading
        style={{
          margin: 0,
        }}
      >
        Bienvenido
      </Heading>
      <FinalForm onSubmit={onSubmit} validate={validate}>
        {formProps => {
          const { handleSubmit, submitting, submitErrors } = formProps

          return <LoginForm isPrivate handleSubmit={handleSubmit} submitting={submitting} submitErrors={submitErrors} />
        }}
      </FinalForm>
    </PrivateAuthLayout>
  )
}

export default PrivateLogin
