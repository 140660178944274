import * as React from 'react'
import { useApolloClient } from '@apollo/react-hooks'
import cookie from 'isomorphic-cookie'
import { useFlag } from 'toggled'

import { analytics } from '~/analytics'
import { loginMutation } from '~/gql/mutations'
import fetchPartnerVendors from '~/hocs/with-app/fetch-partner-vendors'
import { mixpanel } from '~/metrics'
import { forceReloadTo, getCookieConfig, isProduction, notifySentry, sleep } from '~/utils'
import callbackRedirection, { saveSession } from '~/utils/after-auth'
import { cookieNames, flags, timeouts } from '~/utils/constants'
import formValidators from '~/utils/form-validators'
import handleSubmissionErrors from '~/utils/handle-submission-errors'

import PrivateLogin from './private'
import PublicLogin from './public'

const saveStoreId = response => {
  const storeId = response?.data?.login?.user?.cart?.store?.id

  if (storeId) {
    cookie.save(cookieNames.STORE_ID, storeId, { secure: false })
  }
}

const LoginScreen = () => {
  const apolloClient = useApolloClient()

  const hasLoginRequiredFF = useFlag(flags.LOGIN_REQUIRED)

  const hasEnableCodeToLoginFF = useFlag(flags.ENABLE_CODE_TO_LOGIN)

  const hasStoreNavigation = useFlag(flags.STORE_NAVIGATION)

  const hasPartners = useFlag(flags.MULTI_VENDOR_NAVIGATION)

  const onSubmit = async values => {
    const variables = {
      ...values,
      withStore: hasStoreNavigation,
    }

    try {
      const loginResponse = await apolloClient.mutate({
        mutation: loginMutation,
        variables,
      })

      if (hasStoreNavigation) {
        saveStoreId(loginResponse)
      }

      const afterMetrics = async () => {
        mixpanel.login(loginResponse?.data?.login?.user)

        saveSession(loginResponse.data.login)

        if (!hasPartners) {
          return callbackRedirection(hasPartners)
        }

        const partners = await fetchPartnerVendors(apolloClient)

        if (partners.length === 1) {
          const [partnerVendor] = partners

          cookie.save(cookieNames.PARTNER_VENDOR_ENDPOINT, partnerVendor.endpoint, getCookieConfig())

          return callbackRedirection()
        }

        forceReloadTo('/')
      }

      analytics.login()

      await sleep(timeouts.REDIRECTION)

      await afterMetrics()
    } catch (error) {
      // This is temporary to check the logs on the ferrexperto client side.
      const errors = error?.graphQLErrors?.[0]

      if (isProduction && error && !errors?.state) {
        notifySentry(error)
      }

      return handleSubmissionErrors(error)
    }
  }

  const getValidator = () => {
    if (hasEnableCodeToLoginFF) {
      return formValidators.privateLogin
    }

    return formValidators.publicLogin
  }

  return (
    <>
      {hasLoginRequiredFF ? (
        <PrivateLogin onSubmit={onSubmit} validate={getValidator()} />
      ) : (
        <PublicLogin onSubmit={onSubmit} validate={getValidator()} />
      )}
    </>
  )
}

export default LoginScreen
