import * as React from 'react'

import { Meta } from '~/components'
import LoginScreen from '~/screens/login'
import { getMetaInfo } from '~/utils'
import type { GetMetaInfoResult } from '~/utils/get-meta-info'

interface LoginPageProps {
  metaInfo: GetMetaInfoResult
}

function LoginPage(props: LoginPageProps) {
  const { metaInfo } = props

  return (
    <>
      <Meta {...metaInfo} title="Ingresar" noRobots />
      <LoginScreen />
    </>
  )
}

LoginPage.getInitialProps = (context: PageContext): LoginPageProps => {
  return { metaInfo: getMetaInfo(context) }
}

export default LoginPage
