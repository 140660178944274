import { up } from 'styled-breakpoints'
import styled from 'styled-components'

import { getTextStyle, themeGetters } from '~/truck/utils'

const { colors, spacing } = themeGetters

interface DesktopContainerProps {
  reverseWrap?: boolean
  hideSignUp?: boolean
}

const desktopContainerStyles = (props: DesktopContainerProps) => {
  const { reverseWrap = false, hideSignUp } = props

  let flexDirection = 'row'

  if (reverseWrap) {
    flexDirection = 'row-reverse'
  }

  return `
    align-content: flex-start;
    flex-flow: ${flexDirection} wrap;
    justify-content: ${hideSignUp ? 'center' : 'space-between'};
    padding: 0 60px;

    > h4:first-child {
      text-align: center;
    }

    > section {
      margin-top: 0;
      height: calc(100% - 48px);
    }
  `
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: inherit;
  justify-content: flex-start;
  width: 100%;

  > h4:first-child {
    text-align: left;
    width: 100%;
  }

  ${up('lg')} {
    ${desktopContainerStyles}
  }
`

export const Divider = styled.span`
  display: unset;

  ${up('lg')} {
    background-color: ${colors('gray')};
    display: block;
    width: 1px;
  }
`

export const Heading = styled.h4`
  margin-bottom: ${spacing('comfortable')}px;
  ${getTextStyle('h4Semibold')};
  text-align: center;
`

export const Block = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${spacing('comfortable')}px;
  margin-top: ${spacing('comfortable')}px;

  ${up('lg')} {
    width: 360px;
  }
`
